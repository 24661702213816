import React from "react";
import { SEO, PageLayout, Background, Content } from "@bluefin/components";
import { Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class FulfillmentPolicyPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"Fulfillment Policy-hero"}
            />
          }
          subfooter={false}
          className={"fulfillment-page"}
        >
          <Content className={"fulfillment-content"} centered={true}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={12}>
              <Header as={"h1"}>Honey-Dip Fingerz: Fulfilment Policy</Header>
              <p>
                At Honey-Dip Fingerz we have prepared a robust fulfilment policy
                to ensure our customers know how we fulfill your orders and what
                to expect as their orders are processed for pick-up or delivery:
              </p>
            </Content.Markup>
            <Content.Markup isMarkdown={false} textAlign={"left"} width={12}>
              <div>
                <span className={"text-bolded"}>Refund Policy:&nbsp;</span>
                <span>
                  Customers can no longer cancel and get refunded for Ready to
                  Go orders or Pre-orders made, however you will be issued store
                  credit equal the total amount less any applicable taxes for
                  your previous order that can be applied in the future. Your
                  store credits do not expire.
                </span>
              </div>
              <div>
                <span className={"text-bolded"}>Delivery Policy:&nbsp;</span>
                <span>
                  Customers can inform us at the time of placing the order how
                  you want your ordered processed, whether as a pick-up or to be
                  delivered. If customer chooses pick-up they should specify the
                  pick-up date, time. Once order is placed they will get
                  notified via text/email that their order is ready for pick-up.
                  If a customer decides to have their order delivered, they will
                  provide an address and contact details (phone number and email
                  address) so we can arrange a delivery to your address for a
                  fee. Once placed you will be notified when your order has been
                  prepared, picked up, in transit and delivered. If you Opt to
                  have your orders placed through DoorDash or UberEats then you
                  will be charged on the Merchant site any applicable delivery
                  fees unless you decide to pickup your order.
                </span>
              </div>
              <div>
                <span className={"text-bolded"}>Return Policy:&nbsp;</span>
                <span>
                  Once you place your orders and receive it, orders items cannot
                  be returned. However if you have an issue regarding your
                  order, you can call our customer service on 469-734-0568 or
                  email us at&nbsp;
                </span>
                <a href={"mailto:info@honeydipfingerz.com"} target={"_blank"}>
                  info@honeydipfingerz.com
                </a>
                <span>
                  &nbsp;and inform us of your issue. We will do our best to
                  resolve it or issue you a store credit. We strive to always
                  make you happy with our product and we reflect this in the
                  care and level of quality we put into preparing and making
                  your orders.
                </span>
              </div>
              <div>
                <span className={"text-bolded"}>
                  Cancellation Policy:&nbsp;
                </span>
                <span>
                  Once you place your orders and realize you need to cancel
                  either an item or the entire order, you can contact our
                  customer service, and inform us of your decision. If you order
                  is still way out and has not been processed, we may be able to
                  cancel. However if the order items are already being processed
                  (less than 48 hours to delivery), we may decline the
                  cancellation or issue you a store credit as this depends on
                  the size of your order and how far gone we are in the process
                  of making your order. Overall we are still committed to making
                  you satisfied and provide a win-win situation for you,
                  irrespective of your cancellation option.
                </span>
              </div>
            </Content.Markup>
          </Content>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Fulfillment Policy" }) {
      title
    }
  }
`;
